<template>
  <v-container class="pt-120">
    <h1>Privacy Policy</h1>
    <p>
      This privacy policy has been compiled to better serve those who are
      concerned with how their 'Personally identifiable information' (PII) is
      being used online. PII, as used in US privacy law and information
      security, is information that can be used on its own or with other
      information to identify, contact, or locate a single person, or to
      identify an individual in context. Please read our privacy policy
      carefully to get a clear understanding of how we collect, use, protect or
      otherwise handle your Personally Identifiable Information in accordance
      with our website.
    </p>
    <p>
      This privacy policy has been compiled to better serv
    </p>
    <h3>
      What personal information do we collect from the people that visit our
      blog, website or app?
    </h3>
    <p>
      When ordering or registering on our site, as appropriate, you may be asked
      to enter your name, email address, mailing address, credit card
      information or other details to help you with your experience.
    </p>
    <h3>When do we collect information?</h3>
    <p>
      We collect information from you when you register on our site, place an
      order, subscribe to a newsletter, fill out a form or enter information on
      our site.
    </p>
    <h3>How do we use your information?</h3>
    <p>We may use such information in the following ways:</p>
    <ul>
      <li>
        To personalize user's experience and to allow us to deliver the type of
        content and product offerings in which you are most interested.
      </li>
      <li>To improve our website in order to better serve you.</li>
      <li>
        To allow us to better service you in responding to your customer
        services requests.
      </li>
      <li>
        To administer a contest, promotion, survey or other site feature.
      </li>
      <li>To quickly process your transactions.</li>
      <li>
        To send periodic emails regarding your order or other products and
        services.
      </li>
    </ul>
    <h3>How do we protect visitor information?</h3>
    <p>
      Our website is scanned on a regular basis for security holes and known
      vulnerabilities in order to make your visit to our site as safe as
      possible.
    </p>
    <p>
      We use regular Malware Scanning.
    </p>
    <p>
      Your personal information is contained behind secured networks and is only
      accessible by a limited number of persons who have special access rights
      to such systems, and are required to keep the information confidential. In
      addition, all sensitive/credit information you supply is encrypted via
      Secure Socket Layer (SSL) technology.
    </p>
    <p>
      We implement a variety of security measures when a user places an order
      enters, submits, or accesses their information to maintain the safety of
      your personal information.
    </p>
    <p>
      All transactions are processed through a gateway provider and are not
      stored or processed on our servers.
    </p>
    <h3>Do we use 'cookies'?</h3>
    <p>
      Yes. Cookies are small files that a site or its service provider transfers
      to your computer's hard drive through your Web browser (if you allow) that
      enables the site's or service provider's systems to recognize your browser
      and capture and remember certain information. For instance, we use cookies
      to help us remember and process the items in your shopping cart. They are
      also used to help us understand your preferences based on previous or
      current site activity, which enables us to provide you with improved
      services. We also use cookies to help us compile aggregate data about site
      traffic and site interaction so that we can offer better site experiences
      and tools in the future.
    </p>
    <p>We use cookies to:</p>
    <ul>
      <li>Help remember and process the items in the shopping cart.</li>
      <li>Understand and save user's preferences for future visits.</li>
      <li>
        Compile aggregate data about site traffic and site interactions in order
        to offer better site experiences and tools in the future. We may also
        use trusted third party services that track this information on our
        behalf.
      </li>
    </ul>
    <p>
      You can choose to have your computer warn you each time a cookie is being
      sent, or you can choose to turn off all cookies. You do this through your
      browser (like Internet Explorer) settings. Each browser is a little
      different, so look at your browser's Help menu to learn the correct way to
      modify your cookies.
    </p>
    <p>
      If you disable cookies, some features will be disabled It will turn off
      some of the features that make your site experience more efficient and
      some of our services will not function properly.
    </p>
    <h3>
      How can you opt out, remove or modify information you have provided to us?
    </h3>
    <p>
      You can request to have your information removed by clicking on the
      Contact Us/Live Chat button on this or the home page.
    </p>
    <p>
      Please note that we may maintain information about an individual sales
      transaction in order to complete that transaction and for record keeping
      purposes.
    </p>
    <h3>Third Party Disclosures</h3>
    <p>
      We do not sell, trade, or otherwise transfer to outside parties your
      personally identifiable information unless we provide you with advance
      notice. This does not include website hosting partners and other parties
      who assist us in operating our website, conducting our business, or
      servicing you, so long as those parties agree to keep this information
      confidential. We may also release your information when we believe release
      is appropriate to comply with the law, enforce our site policies, or
      protect ours or others' rights, property, or safety.
    </p>
    <p>
      However, non-personally identifiable visitor information may be provided
      to other parties for marketing, advertising, or other uses.
    </p>
    <h3>Third party Links</h3>
    <p>
      Occasionally, at our discretion, we may include or offer third party
      products or services on our website. These third party sites have separate
      and independent privacy policies. We therefore have no responsibility or
      liability for the content and activities of these linked sites.
      Nonetheless, we seek to protect the integrity of our site and welcome any
      feedback about these sites.
    </p>
    <h3>Transfer Of Your Personal Information</h3>
    <p>
      Your information, including personal information, may be transferred to —
      and maintained on — computers located outside of your state, province,
      country or other governmental jurisdiction where the data protection laws
      may differ than those from your jurisdiction.
    </p>
    <p>
      We will take all steps reasonably necessary to ensure that your data is
      treated securely and in accordance with this Privacy Policy and no
      transfer of your personal information will take place to an organization
      or a country unless there are adequate controls in place including the
      security of your data and other personal information.
    </p>
    <h3>Disclosure Of Your Personal Information</h3>
    <p>
      If we are involved in a merger, acquisition or asset sale, your personal
      information may be transferred. We will provide notice before your
      personal information is transferred and becomes subject to a different
      Privacy Policy. Under certain circumstances, we may be required to
      disclose your personal information if required to do so by law or in
      response to valid requests by public authorities (e.g. a court or a
      government agency).
    </p>
    <h3>Retention of Your Personal Information</h3>
    <p>
      We will retain your personal information only for as long as is necessary
      for the purposes set out in this Privacy Policy. We will retain and use
      your information to the extent necessary to comply with our legal
      obligations (for example, if we are required to retain your data to comply
      with applicable laws), resolve disputes, and enforce our legal agreements
      and policies
    </p>
    <h3>
      Information Regarding Your Data Protection Rights Under General Data
      Protection Regulation (GDPR)
    </h3>
    <p>
      For the purpose of this Privacy Policy, we are a Data Controller of your
      personal information.
    </p>
    <p>
      If you are from the European Economic Area (EEA), our legal basis for
      collecting and using your personal information, as described in this
      Privacy Policy, depends on the information we collect and the specific
      context in which we collect it. We may process your personal information
      because:
    </p>
    <ul>
      <li>
        We need to perform a contract with you, such as when you create a Policy
        with us
      </li>
      <li>You have given us permission to do so</li>
      <li>
        The processing is in our legitimate interests and it's not overridden by
        your rights
      </li>
      <li>For payment processing purposes</li>
      <li>To comply with the law</li>
    </ul>
    <p>
      If you are a resident of the European Economic Area (EEA), you have
      certain data protection rights. In certain circumstances, you have the
      following data protection rights:
    </p>
    <ul>
      <li>
        The right to access, update or to delete the personal information we
        have on you
      </li>
      <li>The right of rectification</li>
      <li>The right to object</li>
      <li>The right of restriction</li>
      <li>The right to data portability</li>
      <li>The right to withdraw consent</li>
    </ul>
    <p>
      Please note that we may ask you to verify your identity before responding
      to such requests.
    </p>
    <p>
      You have the right to complain to a Data Protection Authority about our
      collection and use of your personal information. For more information,
      please contact your local data protection authority in the European
      Economic Area (EEA).
    </p>
    <h3>Service Providers</h3>
    <p>
      We employ third party companies and individuals to facilitate our Website
      ("Service Providers"), to provide our Website on our behalf, to perform
      Website-related services or to assist us in analyzing how our Website is
      used. These third-parties have access to your personal information only to
      perform these tasks on our behalf and are obligated not to disclose or use
      it for any other purpose.
    </p>
    <h3>Analytics</h3>
    <p>
      Google Analytics is a web analytics service offered by Google that tracks
      and reports website traffic. Google uses the data collected to track and
      monitor the use of our Service. This data is shared with other Google
      services. Google may use the collected data to contextualize and
      personalize the ads of its own advertising network.
    </p>
    <p>
      You can opt-out of having made your activity on the Service available to
      Google Analytics by installing the Google Analytics opt-out browser
      add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
      analytics.js, and dc.js) from sharing information with Google Analytics
      about visits activity.
    </p>
    <p>
      For more information on the privacy practices of Google, please visit the
      Google Privacy &amp; Terms web page:
      <a target="_blank" href="http://www.google.com/intl/en/policies/privacy/">
        http://www.google.com/intl/en/policies/privacy</a
      >
    </p>
    <h3>Payments processors</h3>
    <p>
      We provide paid products and/or services on our Website. In that case, we
      use third-party services for payment processing (e.g. payment processors).
    </p>
    <p>
      We will not store or collect your payment card details. That information
      is provided directly to our third-party payment processors whose use of
      your personal information is governed by their Privacy Policy. These
      payment processors adhere to the standards set by PCI-DSS as managed by
      the PCI Security Standards Council.
    </p>
    <h3>Contacting Us</h3>
    <p>
      If there are any questions regarding this privacy policy you may contact
      us.
    </p>
  </v-container>
</template>
<script>
export default {
  name: 'Privacy',
  beforeMount() {
    this.$store.commit('signupModal', false)
  },
}
</script>
